exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-aloisia-js": () => import("./../../../src/pages/projects/aloisia.js" /* webpackChunkName: "component---src-pages-projects-aloisia-js" */),
  "component---src-pages-projects-ecco-js": () => import("./../../../src/pages/projects/ecco.js" /* webpackChunkName: "component---src-pages-projects-ecco-js" */),
  "component---src-pages-projects-hathor-js": () => import("./../../../src/pages/projects/hathor.js" /* webpackChunkName: "component---src-pages-projects-hathor-js" */),
  "component---src-pages-projects-winee-3-js": () => import("./../../../src/pages/projects/winee3.js" /* webpackChunkName: "component---src-pages-projects-winee-3-js" */),
  "component---src-pages-projects-wonderland-js": () => import("./../../../src/pages/projects/wonderland.js" /* webpackChunkName: "component---src-pages-projects-wonderland-js" */)
}

